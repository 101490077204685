import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import createStyles from '@guestyci/foundation/createStyles';
import { useToast } from '@guestyci/foundation/Toast';
import useTranslations from '@guestyci/localize/useTranslations';
import history from '@guestyci/history';
import cn from 'classnames';

import { utils, okta } from 'utils';

import Loader from './Loader';
import Layout from '../components/Layout';
import LoginCallback from '../components/LoginCallback/LoginCallback';

import './App.css';

import Login from '../login';
import Logout from '../logout';
import Forgot from '../forgot';

import {
  getSecureAuthCookie,
  safeDatadogAction,
  setDataDogUser,
} from '../login/helpers';
import Impersonation from '../impersonation';
import SessionHandler from '../components/SessionHandler';
import reportFullstory from 'utils/reportFullstory';

const useStyles = createStyles(() => ({
  zendeskForm: {
    display: 'none',
  },
}));

const oktaAuth = new OktaAuth({ ...okta.oidc });

const getCookie = name => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};
const { dimension } = utils.getEnv();

const shouldRedirectToHq = accessToken => {
  if (
    dimension === 'app' &&
    accessToken?.claims &&
    !accessToken?.claims?.accountId
  ) {
    reportFullstory('authentication:hqRedirect', {
      dimension,
      claims: accessToken?.claims,
    });
    return true;
  }
  return false;
};

const redirectToHq = () => {
  const origin = window.location.origin.replace('app', 'hq');
  window.location.replace(`${origin}/auth/session-handler`);
};

const handleZendeskUrl = async (
  accessToken,
  zendeskRedirectUrl,
  setError,
  zendeskInputRef,
  zendeskFormRef
) => {
  const handleError = () => {
    history.push('/login');
    setError('Something wrong with zendesk login. Please try again or contact support');
  };

  try {
    const url = await utils.getZendeskRedirectUrl({
      accessToken,
      zendeskRedirectUrl,
    });

    if (zendeskInputRef?.current && zendeskFormRef?.current) {
      zendeskInputRef.current.value = accessToken.accessToken;
      zendeskFormRef.current.action = url;
      zendeskFormRef.current.submit();
    } else {
      handleError();
    }
  } catch (e) {
    handleError();
  }
};

const getRedirectUrl = () => {
  const ref = window.sessionStorage.getItem('ref');
  if (ref) {
    return ref;
  }
  const res = new URL('/', window.location);
  const isActivationFlow = window.sessionStorage.getItem('isActivationFlow');
  if (isActivationFlow) {
    res.searchParams.append('activation', isActivationFlow);
  }

  return res.toString();
};

const BRANDING = {
  pro:
    'https://static.guesty.com/production/marketing-website/app-default-animation.html',
  lite:
    'https://static.guesty.com/production/marketing-website/app-default-animation-glite.html',
  default:
    'https://static.guesty.com/production/marketing-website/app-default-animation.html',
};

const { pathname } = window.location;
const isRepInstance = pathname.match(/(instance-login|auth\/instance-login)/);

const BrandingBlock = ({ type }) => {
  const currentBranding = BRANDING[type];

  const isLogoutPage = window.location.pathname.indexOf('logout') !== -1;

  if (utils.isHQDomain) {
    return null;
  }

  return (
    <div className={cn('iframe-container', { 'iframe-logout': isLogoutPage })}>
      <iframe
        className="iframe"
        src={currentBranding}
        title="guesty_marketing_animation"
      />
    </div>
  );
};

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const App = () => {
  const styles = useStyles();

  const token = window.localStorage.getItem('token');
  const location = useLocation();
  const query = useQuery();

  const zendeskFormRef = useRef(null);
  const zendeskInputRef = useRef(null);

  const [error, setError] = useState(null);

  const [redirectingText] = useTranslations([
    { id: 'auth_page:layout.redirecting', d: 'Logged in, redirecting...' },
  ]);

  const { addToast } = useToast();

  useEffect(() => {
    try {
      safeDatadogAction(() =>
        window.DD_RUM.setGlobalContextProperty('microapp', {
          name: 'auth-page',
        }));
    } catch (e) {
      console.log('failed to set microapp name', e);
    }
  }, []);

  const customAuthHandler = () => {
    console.log('CUSTOMING AUTH HANDLER');
    history.push('/login');
  };

  const onAuthResume = async () => {
    console.log('RESUMING...');
    history.push('/login');
  };

  const { return_to: zendeskRedirectUrl } = history.getQueryParams();

  const shouldRedirect =
    !zendeskRedirectUrl &&
    token &&
    !query.get('token') &&
    process.env.NODE_ENV !== 'development';

  if (shouldRedirect && location.pathname !== '/logout') {
    window.location.replace('/');
    return null;
  }

  if (error) {
    addToast.danger(error);
  }

  const handleOnTokenRecieved = async () => {
    try {
      const accessToken = await oktaAuth.tokenManager.get('accessToken');
      utils.eventsLogger.unifiedLoginDio.track(
        'logged_successfully',
        'submit',
        {
          userId: accessToken.claims.userId,
        }
      );

      const eventData = {
        nowMinusIat: Date.now() / 1000 - accessToken.claims.iat,
        userId: accessToken.claims.userId,
        accountId: accessToken.claims.accountId,
        email: accessToken.claims.sub,
        name: accessToken.claims.fullName,
      };

      if (accessToken) {
        utils.saveUserData(accessToken.accessToken);
      }

      if (60 * 1 < Math.abs(eventData.nowMinusIat)) {
        utils.reportFullstory('authentication:ubnormalClockMargin', eventData);
      }
    } catch (logError) {
      console.log(logError);
    }
  };

  const handleRestoreOriginalUri = async () => {
    await handleOnTokenRecieved();

    try {
      await getSecureAuthCookie();
    } catch (e) {
      console.error(e);
    }

    const accessToken = await oktaAuth.tokenManager.get('accessToken');

    try {
      const zendeskRedirectUrl = window.sessionStorage.getItem('zendeskRedirectUrl');

      if (zendeskRedirectUrl) {
        await handleZendeskUrl(
          accessToken,
          zendeskRedirectUrl,
          setError,
          zendeskInputRef,
          zendeskFormRef
        );
        return;
      }

      await setDataDogUser();
      if (shouldRedirectToHq(accessToken)) {
        redirectToHq();
        return;
      }
      const redirectUrl = getRedirectUrl();
      window.sessionStorage.clear();
      window.location.replace(redirectUrl);
    } catch (e) {
      console.error(e);
    }
  };

  const branding = getCookie('gstUserType');

  return (
    <div
      className={cn(
        'wrapper',
        (utils.isHQDomain ||
          isRepInstance ||
          location.pathname === '/login/callback') &&
          'one-column'
      )}
    >
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={handleRestoreOriginalUri}
      >
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route
            path="/login/callback"
            render={props => {
              return (
                <LoginCallback
                  {...props}
                  oktaAuth={oktaAuth}
                  authState={oktaAuth.authStateManager.getAuthState()}
                  loadingElement={<Loader text={redirectingText} />}
                  onAuthResume={onAuthResume}
                />
              );
            }}
          />
          <Route path="/logout" component={Logout} />
          <Route path="/impersonate" component={Impersonation} />
          <Route path="/session-handler" component={SessionHandler} />
          <Route path="/instance-login" component={SessionHandler} />
          <Route>
            <Layout>
              <Switch>
                <Route path="/forgot" component={Forgot} />
                <Route>
                  <Redirect to={{ pathname: '/login' }} />
                </Route>
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </Security>
      {!isRepInstance && location.pathname !== '/login/callback' && (
        <BrandingBlock type={branding || 'default'} />
      )}
      <form
        className={styles.zendeskForm}
        ref={zendeskFormRef}
        action={null}
        method="post"
      >
        <input ref={zendeskInputRef} type="hidden" name="jwt"></input>
      </form>
    </div>
  );
};

export default App;
