export default {
  production: {
    // TODO: Update production info
    clientId: '',
    issuer: '',
    googleIDPId: '0oaawc7ghqmY4AWqV5d7',
  },
  staging5: {
    clientId: '0oalmpcpwauuv3LhL5d7',
    issuer: 'https://login-staging5.guesty.com/oauth2/auslmpnx3d7NMUhBG5d7',
    googleIDPId: '0oalkgyhby0VWj9KK5d7',
  },
};
