export default {
  production: {
    clientId: '0oa2ul8rlePlNldiQ5d7',
    issuer: 'https://login.guesty.com/oauth2/aus2jlqfuwAsNNp3D5d7',
    googleIDPId: '0oaawc7ghqmY4AWqV5d7',
  },
  trial: {
    clientId: '0oa2ul8rlePlNldiQ5d7',
    issuer: 'https://login.guesty.com/oauth2/aus2jlqfuwAsNNp3D5d7',
  },
  staging0: {
    clientId: '0oa5uurgz2gTtijhN5d7',
    issuer: 'https://login-staging0.guesty.com/oauth2/aus5uux0nrEFk69cb5d7',
  },
  staging1: {
    clientId: '0oae0z8pepef1eMS85d7',
    issuer: 'https://login-staging1.guesty.com/oauth2/ause0z4rbpzy7HBGl5d7',
  },
  staging2: {
    clientId: '0oa3ngzbghfTEbfCl5d7',
    issuer: 'https://login-staging2.guesty.com/oauth2/aus3nh12muTccaqOE5d7',
  },
  staging3: {
    clientId: '0oa3ombemqEw6cT3W5d7',
    issuer: 'https://login-staging3.guesty.com/oauth2/aus3ombig66YLfhRC5d7',
  },
  staging4: {
    clientId: '0oa3omohnx0Xwjrx05d7',
    issuer: 'https://login-staging4.guesty.com/oauth2/aus3omosg1lLlkHm65d7',
  },
  staging5: {
    clientId: '0oae0zkmjeMcNzAHy5d7',
    issuer: 'https://login-staging5.guesty.com/oauth2/ause0zlltxJ1XicAi5d7',
    googleIDPId: '0oae8uqtheDrTx4GM5d7',
  },
  staging6: {
    clientId: '0oa3os7v0eicQ5DoU5d7',
    issuer: 'https://login-staging6.guesty.com/oauth2/aus3osa2uwogb7Gec5d7',
  },
  staging7: {
    clientId: '0oa3m5ea29oisL4aN5d7',
    issuer: 'https://login-staging7.guesty.com/oauth2/aus3m5gnb3kLLJ7ju5d7',
  },
  staging8: {
    clientId: '0oa3oveun3zGzzfpi5d7',
    issuer: 'https://login-staging8.guesty.com/oauth2/aus3ovcl3qiAuFTtz5d7',
  },
  staging9: {
    clientId: '0oa3ovlaj3EUxZqiR5d7',
    issuer: 'https://login-staging9.guesty.com/oauth2/aus3ovjc23Cflnf2e5d7',
  },
  staging10: {
    clientId: '0oa3ovo8dp3S5wxkG5d7',
    issuer: 'https://login-staging10.guesty.com/oauth2/aus3ovn0nt5AyybWn5d7',
  },
  staging11: {
    clientId: '0oa3ozd7zmmAfqcdo5d7',
    issuer: 'https://login-staging11.guesty.com/oauth2/aus3ozf311RPHuiFB5d7',
  },
  staging12: {
    clientId: '0oa3p7dy1xC19sEFf5d7',
    issuer: 'https://login-staging12.guesty.com/oauth2/aus3p7fqgqZi3Vc3j5d7',
  },
  staging13: {
    clientId: '0oa3p7juvrddC0bhi5d7',
    issuer: 'https://login-staging13.guesty.com/oauth2/aus3p7iaksbjL4C215d7',
  },
  staging14: {
    clientId: '0oa51f7cu3NPfdHkT5d7',
    issuer: 'https://login-staging14.guesty.com/oauth2/aus51f922aa0QcZbv5d7',
  },
  staging15: {
    clientId: '0oa5wyjcucA8FZPFg5d7',
    issuer: 'https://login-staging15.guesty.com/oauth2/aus5wyckmmWGo44sy5d7',
  },
  staging16: {
    clientId: '0oa5uxaa3wjLeP9hP5d7',
    issuer: 'https://login-staging16.guesty.com/oauth2/aus5ux7o96osjeeF15d7',
  },
  staging17: {
    clientId: '0oa5uushyjPhjSxEY5d7',
    issuer: 'https://login-staging17.guesty.com/oauth2/aus5ut1ydkJ9Pp5RE5d7',
  },
  staging18: {
    clientId: '0oae0zp42e5gQ9Sve5d7',
    issuer: 'https://login-staging18.guesty.com/oauth2/ause0zru2nOu70W8d5d7',
  },
  staging19: {
    clientId: '0oa8afm0y9JAyUdK95d7',
    issuer: 'https://login-staging19.guesty.com/oauth2/aus8aflbh52THqYg85d7',
  },
  staging20: {
    clientId: '0oa8b4wvupBVCdcxS5d7',
    issuer: 'https://login-staging20.guesty.com/oauth2/aus8b4tj8wp4nPjnL5d7',
  },
  staging21: {
    clientId: '0oagitg9jzKwao2Bq5d7',
    issuer: 'https://login-staging21.guesty.com/oauth2/ausgiteqa2oY8Pqhr5d7',
  },
  sandbox: {
    clientId: '0oaggw5it1YBHTmwW696',
    issuer: 'https://login-sandbox.guesty.com/oauth2/ausggvw8u7aMPqDpA696',
  },
};
