import React, { useEffect, useRef } from 'react';
import { Security } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import { okta } from 'utils';
import { OktaAuth } from '@okta/okta-auth-js';
import createStyles from '@guestyci/foundation/createStyles';

const isProduction = process.env.CLUSTER === 'production'

const useStyles = createStyles(
  theme => ({
    '@global': {
      '#okta-sign-in.auth-container.main-container': {
        margin: 0,
        padding: 0,
        minHeight: 0,
      },
      '#okta-sign-in.auth-container .auth-content': {
        padding: 0,
        margin: 0,
      },
      '#okta-sign-in .auth-header': {
        display: 'none',
      },
      '#okta-sign-in .primary-auth': {
        padding: 0,
      },
    },
  }),
  { name: 'GoogleConnect' }
);

const getWidgetConfig = oidc => {
  const { issuer, clientId, scopes, redirectUri, googleIDPId } = oidc;
  return {
    baseUrl: issuer.split('/oauth2')[0],
    clientId,
    redirectUri,
    authParams: {
      issuer,
      scopes,
      prompt: 'consent',
    },
    features: {
      registration: false,
      rememberMe: false,
      showPasswordToggleOnSignInPage: false,
      idpDiscovery: false,
    },
    idps: [{ type: 'GOOGLE', id: googleIDPId }],
    prompt: 'consent',
    idpDisplay: 'PRIMARY',
  };
};

const productionOrigins = [
  'https://app.guesty.com',
  'https://www.guesty.com'
];

const isAllowedOrigin = () => {
  if(!isProduction) return true;
  const origin = window.location.origin;
  return productionOrigins.includes(origin);
};

const postMessageToParent = message => {
  if (window.self === window.top) {
    console.log('Not in an iframe, message not sent');
    return;
  }

  const parentOrigin = document.referrer ? new URL(document.referrer).origin : null;

  if (isProduction) {
    if (productionOrigins.includes(parentOrigin)) {
      window.parent.postMessage(message, parentOrigin);
      console.log(`Message sent to production origin: ${parentOrigin}`);
    } else {
      console.error(`Attempted to send message to non-allowed origin in production: ${parentOrigin}`);
    }
  } else {
    // In non-production environments, allow all origins
    console.log(`Sending message to non-production origin: ${parentOrigin || '*'}`);
    window.parent.postMessage(message, '*');
  }
};

const adjustWidgetStyling = () => {
  const container = document.querySelector('#okta-sign-in');
  if (container) {
    // Remove unwanted elements
    ['okta-form-title', 'auth-divider', 'auth-footer'].forEach(className => {
      const element = container.querySelector(`.${className}`);
      if (element) element.remove();
    });
    container.querySelector('form')?.remove();

    // Adjust container styling
    Object.assign(container.style, {
      width: 'auto',
      height: 'auto',
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0',
      background: 'transparent', // Make the main container transparent
    });

    // Adjust button container
    const buttonContainer = container.querySelector('.primary-auth-container');
    if (buttonContainer) {
      Object.assign(buttonContainer.style, {
        padding: '0',
        background: 'transparent',
      });
    }

    // Adjust button styling
    const button = container.querySelector('.social-auth-google-button');
    if (button) {
      Object.assign(button.style, {
        margin: '0',
        borderRadius: '50px', // Adjust this value for more or less rounding
        overflow: 'hidden',
      });

      // Adjust the button's child elements
      const buttonChildren = button.querySelectorAll('*');
      buttonChildren.forEach(child => {
        child.style.borderRadius = '50px'; // Match the button's border radius
      });
    }

    // Ensure the outer container is also transparent
    const outerContainer = document.getElementById('okta-signin-container');
    if (outerContainer) {
      outerContainer.style.background = 'transparent';
    }
  }
};
const GoogleConnect = () => {
  const oidc = { ...okta.marketingOidc };
  const oktaAuth = new OktaAuth(oidc);
  const widgetRef = useRef();

  useStyles();

  useEffect(() => {
    if (!isAllowedOrigin()) {
      console.error('Unauthorized domain');
      return; // Exit early if not an allowed origin
    }
    if (!widgetRef.current) return;

    const widget = new OktaSignIn(getWidgetConfig(oidc));

    const handleSignInSuccess = async tokens => {
      const email = tokens.idToken.claims.email;
      const isExistingUser = tokens.accessToken.claims.userId;
      oktaAuth.tokenManager.setTokens(tokens);

      try {
        const user = await oktaAuth.getUser();
        oktaAuth.tokenManager.clear();

        const {
          given_name: firstName,
          family_name: lastName,
          sub: oktaId,
        } = user;

        if (isExistingUser) {
          postMessageToParent({ type: 'GOOGLE_LOGIN_SUCCESS_EXISTING_USER' });
        } else {
          postMessageToParent({
            type: 'GOOGLE_LOGIN_SUCCESS',
            oktaId,
            email,
            firstName,
            lastName,
          });
        }
      } catch (error) {
        postMessageToParent({
          type: 'GOOGLE_LOGIN_ERROR',
          error: error.message || 'Failed to login with Google',
        });
        console.error('Error getting user:', error);
      }
    };

    const initializeWidget = async () => {
      try {
        const tokens = await widget.showSignInToGetTokens({
          el: widgetRef.current,
        });
        await handleSignInSuccess(tokens);
      } catch (err) {
        postMessageToParent({
          type: 'GOOGLE_LOGIN_ERROR',
          error: err.message || 'Failed to login with Google',
        });
      }
    };

    void initializeWidget();

    widget.on('afterRender', context => {
      if (context.controller === 'primary-auth') {
        adjustWidgetStyling();
      }
    });

    return () => widget.remove();
  }, [oktaAuth, oidc]);

  if (!isAllowedOrigin()) {
    console.error('Unauthorized domain');
    return null;
  }
  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={() => console.error('onAuthRequired')}
      restoreOriginalUri={()=> console.error('restoreOriginalUri')}
    >
      <div>
        <div
          id="okta-signin-container"
          style={{ position: 'absolute', top: 0, left: 0 }}
        >
          <div ref={widgetRef} />
        </div>
      </div>
    </Security>
  );
};

export default GoogleConnect;
