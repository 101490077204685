import React from 'react';
import createHistory from '@guestyci/history/createHistory';
import LocalizationProvider from '@guestyci/localize/LocalizationProvider';
import { Route, Router, Switch } from 'react-router-dom';
import '@guestyci/foundation/style/styles.css';
import ToastProvider from '@guestyci/foundation/Toast';

import { utils } from 'utils';

import App from './app/App';
import GoogleConnect from './googleConnect';

utils.eventsLogger.initLoggers();

const history = createHistory({ baseUrl: '/auth', isFragment: false });

const AppProvider = () => {
  return (
    <Router history={history}>
      <LocalizationProvider>
        <ToastProvider>
          <Switch>
            <Route exact path="/google-connect" component={GoogleConnect} />
            <Route path="/" component={App} />
          </Switch>
        </ToastProvider>
      </LocalizationProvider>
    </Router>
  );
};

export { AppProvider };
