import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import useTranslations from '@guestyci/localize/useTranslations';
import history from '@guestyci/history';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import { Row, Col } from '@guestyci/foundation/next/Layout';
import { FlatButton } from '@guestyci/foundation';
import Icon from '@guestyci/foundation/next/Icon';
import { ReactComponent as HelpIcon } from '@guestyci/foundation/icons/BtnHelp.svg';
import { utils } from 'utils';

import { reportActionToDataDog } from '../../login/helpers';
import { ReactComponent as BackIcon } from '../../assets/back-icon.svg';
import { ReactComponent as GuestyLogo } from '../../assets/guesty-logo-dark-blue.svg';

export const useStyles = createStyles(
  {
    errorInfoContainer: {
      height: '100%',
    },
    errorTextsContainer: {
      height: '50%',
      width: 300,
    },
    errorTitle: {
      color: '#1f2937 !important',
      fontSize: 25,
      fontWeight: 500,
    },
    errorMessageText: {
      color: '#1f2937 !important',
      fontSize: 14,
      fontWeight: 400,
      textAlign: 'center',
    },
    actionButton: {
      color: '#1f2937 !important',
      height: 40,
      fontSize: 14,
      fontWeight: 600,
    },
    supportButton: {
      boxShadow: 'none !important',
    },
  },
  { name: 'LoginCallback' }
);

const isGoogleLoginByIdp = async oktaAuth => {
  try {
    const idToken = await oktaAuth.tokenManager.get('idToken');
    return idToken?.claims?.idp?.startsWith('0oaa');
  } catch (e) {
    return false;
  }
};

const reportErrorLogin = error => {
  try {
    reportActionToDataDog(`Login Error - ${error}`, { error });
  } catch (er) {
    console.error('Error: Fail to fetch google login data and report');
  }
};

const reportSuccessLogin = ({ isGoogleLogin }) => {
  try {
    reportActionToDataDog(`Login Success - ${isGoogleLogin ? 'Google' : 'Password'}`);
  } catch (er) {
    console.error('Error: Fail log success data');
  }
};

const OktaError = ({ err }) => {
  utils.useDocumentTitle('Login');
  const classes = useStyles();
  const [
    errorTitle,
    wrongSessioneText,
    googleErrorText,
    inactiveAccountText,
    tryAgainText,
    backToLginText,
    helpCenterText,
  ] = useTranslations([
    {
      id: 'auth_page:layout.error.title',
      d: 'Something went wrong',
    },
    {
      id: 'auth_page:layout.error.wrong_sessions',
      d:
        'Please check if you have any Guesty tabs open. If you do, close them and try to login again',
    },
    {
      id: 'auth_page:layout.error.google',
      d:
        'This user hasn’t been validated. Please turn to support to validate your user in order to login to Guesty',
    },
    {
      id: 'auth_page:layout.error.inactive_account',
      d:
        'You tried to login too many times. Please wait 10 minutes before trying again.',
    },
    {
      id: 'auth_page:layout.error.try_again',
      d: 'Please try again',
    },
    {
      id: 'auth_page:layout.error.back_to_login',
      d: 'Back to login',
    },
    {
      id: 'auth_page:layout.error.help_center',
      d: 'Help center',
    },
  ]);

  const getTextByError = error => {
    if (error?.includes('User creation was disabled.')) {
      return googleErrorText;
    }
    if (error?.includes('User status is invalid')) {
      return inactiveAccountText;
    }
    if (error?.includes('Could not load PKCE codeVerifier from storage')) {
      return wrongSessioneText;
    }
    return tryAgainText;
  };

  const goToLogin = () => history.push('/login');
  const openHelpCenter = () =>
    window.open(
      'https://help.guesty.com/hc/en-gb/articles/9369852308509-Troubleshooting-Login-Issues',
      '_blank'
    );

  const error = err?.message ? err?.message : err?.toString();
  const errorText = getTextByError(error);
  console.error('Okta Error: login failed');
  reportErrorLogin(error);

  return (
    <p>
      <Col
        align="center"
        justify="center"
        className={classes.errorInfoContainer}
      >
        <Icon svg={GuestyLogo} width={90} height={60} />
        <Col
          className={classes.errorTextsContainer}
          spacing={5}
          align="center"
          justify="center"
        >
          <TextField className={classes.errorTitle} variant="h2">
            {errorTitle}
          </TextField>
          {errorText && (
            <TextField className={classes.errorMessageText} variant="h4">
              {errorText}
            </TextField>
          )}
          <Row spacing={2} align="center" justify="center">
            <FlatButton className={classes.actionButton} onClick={goToLogin}>
              <Row spacing={1} align="center">
                <Icon svg={BackIcon} width={16} height={16} />
                <TextField>{backToLginText}</TextField>
              </Row>
            </FlatButton>
            <FlatButton
              className={cn(classes.actionButton, classes.supportButton)}
              onClick={openHelpCenter}
            >
              <Row spacing={1} align="center">
                <Icon svg={HelpIcon} width={16} height={16} />
                <TextField>{helpCenterText}</TextField>
              </Row>
            </FlatButton>
          </Row>
        </Col>
      </Col>
    </p>
  );
};

export default function LoginCallback({
  oktaAuth,
  authState,
  loadingElement = null,
  onAuthResume,
  errorComponent,
}) {
  const [callbackError, setCallbackError] = useState(null);

  const ErrorReporter = errorComponent || OktaError;

  const handelAuth = async () => {
    const isInteractionRequired =
      oktaAuth.idx.isInteractionRequired ||
      oktaAuth.isInteractionRequired.bind(oktaAuth);

    if (onAuthResume && isInteractionRequired()) {
      onAuthResume();
      return;
    }

    try {
      await oktaAuth.handleLoginRedirect();
      const isGoogleLogin = await isGoogleLoginByIdp(oktaAuth);
      reportSuccessLogin({ isGoogleLogin });
    } catch (error) {
      setCallbackError(error);
    }
  };

  useEffect(() => {
    handelAuth();
  }, [oktaAuth]);

  const authError = authState?.error;
  const displayError = callbackError || authError;

  if (displayError) {
    return <ErrorReporter err={displayError} />;
  }

  return loadingElement;
}
